<template>
  <div class="d-flex">
    <div v-for="i in 12" :key="i" class="month-progress lightGrey3" :class="{'mr-1': i !== 12}">
      <div class="month-progress-color" :style="{width: `${Math.min(((data.hasClaim - (i - 1) * 30) / 30 * 100).toFixed(), 100)}%`, background: '#53A29C', zIndex: 9}"></div>
      <div class="month-progress-color" :style="{width: `${Math.min(((data.totalTime - (i - 1) * 30) / 30 * 100).toFixed(), 100)}%`, background: '#F0CD8F', zIndex: 8}"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  }
}
</script>

<style lang="scss">
.month-progress {
  width: 100%;
  height: 2px;
  border-radius: 5px;
  position: relative;
  .month-progress-color {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 5px;
  }
}
</style>