<template>
  <div class="asset-page-wrap d-flex flex-column flex-sm-row justify-center">
    <subnav currPage="footerAsset" showPrevious customPreviousBtn>
      <template #previousBtn>
        <div
          class="font-weight-bold rem-1 darkGrey--text white previous-page-btn pl-5 pr-3 py-1 can-click"
          v-text="$t(summaryExpanded ? 'backToPreviousPage' : 'assetsSummary')"
          @click="summaryExpanded = !summaryExpanded"
        />
      </template>
    </subnav>
    <div class="asset-summary white mr-2 pt-4 pb-9 d-sm-flex flex-column" :class="summaryExpanded ? 'expanded d-flex px-5' : 'px-3 d-none'">
      <img class="d-none d-sm-block mb-6 can-click" :class="summaryExpanded ? 'mr-auto' : 'mx-auto'" src="@/assets/img/icon-expand.svg" height="20px" alt="icon-expand" @click="summaryExpanded = !summaryExpanded">
      <div v-for="(item, index) in summaryList" :key="item.title">
        <div class="summary-title primary--text font-weight-bold mx-auto">{{ $t(item.title) }}</div>
        <div v-if="summaryExpanded" class="summary-content rem-24 font-weight-bold">{{ (item.value ? (item.value).toLocaleString() : 0) }} USDT</div>
        <v-divider v-if="index !== summaryList.length - 1" class="my-6" />
      </div>
    </div>
    <div class="asset-index">
      <v-container class="my-5 mx-sm-1 pt-sm-5">
        <div
          class="asset-item d-flex align-stretch mb-5"
          v-for="(item, i) in orders"
          :key="i"
        >
          <div class="asset-info flex-grow-1 rounded-lg can-click py-2 px-3 mr-2" @click="toOrderDetails(item)">
            <div class="d-flex align-start">
              <img :src="`${require(`@/assets/img/icon-logo-${item.token_name.toLowerCase()}.svg`)}`" :alt="`${item.token_name.toLowerCase()}-${$route.params.interestToken}`" class="mr-2 token-img">
              <div class="flex-grow-1 rem-0">
                <div class="lightGrey2--text d-flex justify-space-between">
                  <span>{{ $t('orderId', {id: item.id}) }}</span>
                  <span>{{ $t('orderTime', {date: item.totalTime}) }}</span>
                </div>
  
                <div class="d-flex justify-space-between mb-2">
                  <div>
                    <div class="font-share-tech font-weight-bold rem-8 mb-1 primary--text">{{ $t('orderInterest', { amount: item.payment_amount }) }}</div>
                    <div class="lightGrey2--text mb-1 rem-0">{{ $t('orderRate', {value: item.rate, interestToken: $route.params.interestToken.toUpperCase(), depositToken: item.token_name}) }}</div>
                    <div class="font-share-tech font-w-4 mb-1">
                      <span class="rem-8 rem-sm-10 mr-1" style="color: #00A77B;">{{ item.amount.toLocaleString() }} {{ item.token_name }}</span>
                      <span class="rem-3 rem-sm-5 lightGrey3--text">/ {{ (item.amount * item.rate).toLocaleString() }} {{ $route.params.interestToken.toUpperCase() }}</span>
                    </div>
                  </div>
                  <img src="@/assets/img/icon-setting.svg" alt="details">
                </div>
              </div>
            </div>
            <contractProgress :data="item"></contractProgress>
          </div>
          <div class="asset-btns font-w-4 text-center">
            <div class="asset-btn rounded-lg d-flex flex-column justify-center align-center px-4 mb-1" :class="item.status ? 'can-click' : 'disabled'" :style="{borderColor: now - item.start_day > (60 * 60 * 24) ? '#FF5B45' : ''}" @click="showDialog('redeem', i)">
              <img :src="`${require(`@/assets/img/icon-polygon-${item.token_name.toLowerCase()}-${!item.status ? 'disabled' : now - item.start_day > (60 * 60 * 24) ? 'warning' : 'primary'}.svg`)}`" :alt="item.token" class="mb-1">
              <span class="rem-0" :class="!item.status ? '' : now - item.start_day > (60 * 60 * 24) ? 'warning-text' : 'lightPrimary2--text'">{{ $t('redeemDepositToken') }}</span>
            </div>
            <div class="asset-btn rounded-lg d-flex flex-column justify-center align-center px-4" :class="item.status && item.payment_amount !== '0' ? 'can-click' : 'disabled'" @click="showDialog('claim', i)">
              <img :src="`${require(`@/assets/img/icon-polygon-${$route.params.interestToken}-${item.status && item.payment_amount !== '0' ? 'primary' : 'disabled'}.svg`)}`" :alt="item.token" class="mb-1">
              <span class="rem-0" :class="item.status && item.payment_amount !== '0' ? 'lightPrimary2--text' : ''">{{ $t('claimInterest') }}</span>
            </div>
          </div>
        </div>
      </v-container>
  
      <v-dialog persistent fullscreen v-model="loadingShow" style="height: 100vh;">
        <div class="d-flex flex-column justify-center align-center" style="height: 100%; background-color: rgba(255, 255, 255, .9);">
          <img class="mb-10" src="@/assets/img/icon-loading.svg" alt="loading">
          <div style="margin-bottom: 120px">{{ $t('waitForChainData') }}</div>
          <div class="mb-5">{{ $t('depositNowIfHavent') }}</div>
          <btnPill
            btnText="depositImmediately"
            :maxWidth="$store.state.nowWidth > 960 ? '350px' : '267px'"
            @clickBtn="$router.push({name: 'Deposit', params: {lang: $store.state.locale, interestToken: $store.state.interestTokens[0].token, depositToken: $store.state.depositTokens[0].token}})"
          />
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import btnPill from '@/components/btn-pill.vue'
import subnav from '@/components/subnav.vue'
import contractProgress from '@/components/progress.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data () {
    return {
      currIndex: 0,
      loadingShow: true,
      summaryExpanded: false,
      summaryList: []
    }
  },
  components: {
    btnPill,
    subnav,
    contractProgress,
  },
  methods: {
    showDialog(type, index) {
      let item = this.orders[index]
      if (type === 'redeem') {
        if (!item.status) return;
      } else {
        if (!item.status || item.payment_amount === '0') return;
      }
      this.currIndex = index
      this.$store.commit('updateDialog', {isShow: true, type: type, data: item, currIndex: index})
    },
    toOrderDetails(item) {
      this.$router.push({
        name: 'Order',
        params: {
          lang: this.$store.state.locale,
          interestToken: this.$route.params.interestToken,
          depositToken: item.token_name.toLowerCase(),
          orderId: item.id,
          data: item,
          currIndex: this.currIndex
        }
      })
    }
  },
  async created() {
    this.loadingShow = true
    // this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitLoading')})
    await this.getOrders()
    const claimableTotalInterestAmount = this.orders.reduce((accu, item) => accu += parseFloat(item.payment_amount), 0)
    const claimedTotalInterestAmount = this.orders.reduce((accu, item) => accu += parseFloat(item.take_payment_amount), 0)
    this.summaryList = [
      { title: 'claimableTotalInterest', value: claimableTotalInterestAmount },
      // { title: 'referenceTotalInterest', value: 0 },
      { title: 'claimedTotalInterest', value: claimedTotalInterestAmount }
    ]
    this.loadingShow = false
    // this.$store.commit('updateLoading', {isShow: false, text: ''})
  }
}
</script>
<style lang="scss">
.asset-page-wrap {
  margin: 0 auto;
  .previous-page-btn {
    position: absolute;
    height: 24px;
    bottom: -12px;
    box-shadow: 0px 0.8px 0.8px rgba(0, 0, 0, 0.25);
  }
}
.asset-summary {
  border-radius: 11px;
  width: 50%;
  max-width: 56px;
  @include dai_vuetify_sm {
    width: 100%;
  }
  .summary-title {
    writing-mode: vertical-rl;
    text-orientation: upright;
    font-size: 12px;
  }
  .summary-content {
    color: #1FB28B;
  }
  &.expanded {
    max-width: 420px;
    @include dai_vuetify_sm {
      margin-top: 24px;
      max-width: 100%;
    }
    img[alt="icon-expand"] {
      transform: rotate(180deg);
    }
    .summary-title {
      writing-mode: horizontal-tb;
      font-size: 20px;
    }
  }
}
.asset-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    border-radius: 11px;
    background: white;
  }
  .asset-item {
    img.token-img {
      margin-top: 20px;
    }
    .asset-info {
      background: white;
      border: 1px solid var(--v-primary-base);
    }

    .asset-btns {
      .asset-btn {
        height: calc((100% - 4px) / 2);
        background: var(--v-primary-base);
        &:nth-child(2) {
          border: 1px solid var(--v-lightPrimary2-base);
        }
        &.disabled {
          color: #BDBDBD !important;
          background: #878787;
          border-color: #878787 !important;
        }
      }
    }
    .warning-text {
      color: #FF5B45;
    }
  }
}

.v-input.text-center {
  input {
    text-align: center;
  }
}

img[alt="loading"] {
  animation: spin 1s infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>